.dropzone{
    border: 1px dashed lightskyblue;
    border-radius: 10px;
    width: 100%;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container{
    width: 100%;
    height: 250px;
    min-height: 250px;
    max-height: 250px;
    /* border:1px solid lightslategray; */
    /* padding:10px; */
}