.logo {
    position:relative;
    width: 40px;
    height: 40px;
    transition: all 0.3s ease-out;
}

.logo:hover{
    transform: scale(1.02);
}

.toolbar {
    display:flex;
    justify-Content:space-between;
    max-height: 70px;
    min-height: 70px;
    /* background-image: url("../images/iconossolos.png"); */
    /* background-repeat: repeat-x; */
    /* background-size: contain; */
}

.avatar{
    position: relative;
    left: 15px;
    top: 0px;
}

.avatar-contain{
    /* background-image: url("../images/pestanaverde.png");
    background-repeat: no-repeat;
    background-size: 100% 100%; */
}