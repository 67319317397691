.button {
  color: #2e8b57;
  border: 1px solid #2e8b57;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  height: 30px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;
}

.button-active {
  color: white;
  border: 2px solid #aaa;
  background-color: #2e8b57;
  padding: 10px;
  border-radius: 5px;
  height: 30px;
  line-height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;
}

.button:hover {
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 5px #aaaaaa;
  transform: translateY(-0.5px);
  cursor: pointer;
}

.button-active:hover {
  cursor: pointer;
}

.root {
  width: 100%;
}
