.container-image-one {
  width: "100%";
  /* height: 140px; */
  z-index: 501;
  position: relative;
  top: -10px;
}

.ant-image-preview-body img {
  border: 2px solid #0c7140; /* Marco verde */
  border-radius: 8px; /* Opcional: esquinas redondeadas */
}

