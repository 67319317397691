
.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Asegura que esté detrás del contenido */
  }
  
  /* Contenedor principal con fondo oscuro semitransparente */
.login-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background: rgba(
      0,
      0,
      0,
      0.5
    ); /* Oscurece un poco el fondo para mayor contraste */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .login-root{
    height: 100vh;
    width: 100vw;
    padding:20px;
    box-shadow: 9px 9px 5px -2px rgba(0,0,0,0.59);
    margin-top: 0px;
}

.login-sub-root{
    min-height: 500px;
    border-radius: 5px;
}

.login-cenefa{
    height: 250px;
    max-height: 250px;
    display: flex;
    justify-content: center;
    margin:0px;
    padding:0px;
}

.login-texfield, .login-button{
    padding:10px;
    margin:0px 20px 20px 20px;
}
