.root{
    width:100%;
    overflow-y: hidden;
}

.a-proform{
    font-weight: bold;
    border-radius: 5px;
    background-color: #0E3B5F;
    color:white;
    width: 25px;
    height: 14px;
    display: block;
    text-align: center;
    transition: all 0.3s;
}

.a-proform:hover{
    cursor:pointer;
    color:navy;
    background-color:#CAD226;
}

.b-proform{
    font-weight: bold;
    /* width:auto; */
    font-size: 10px;
    color: #0E3B5F;
    transition: all 0.3s;
    display: block;
    text-align: center;
}

.b-proform:hover{
    cursor:pointer;
    color:navy;
}