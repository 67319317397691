.card-component {
  padding: 2px;
  height: 150px;
  min-height: 150px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 1;
  position: relative;
  box-shadow: 0 0 9px rgb(1, 16, 45);
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  background: linear-gradient(
    180deg,
    rgba(46, 139, 87, 1) 0%,
    rgb(65, 162, 107) 35%,
    rgb(117, 217, 160) 100%
  );
  color: #0e3b5f;
}

.title {
  margin-top: 10px;
  font-weight: bold;
  font-size: 1rem;
}

.card-component:hover {
  cursor: pointer;
  color: white;
  box-shadow: 0 0 10px rgb(14, 16, 128);
  /* transform: translateY(-5px); */
  transform: scale(1.09);
  background-color: rgba(14, 16, 128, 0.888);
}

@media (max-width: 768px) {
  .title {
    margin-top: 8px;
    font-size: 12px;
  }

  .card-component:hover {
    transform: scale(1.06);
  }
}
