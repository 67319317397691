  .nested {
    padding:0px;
    margin:5px;
    color:#fff;
  }

  .listit {
    color:#271080;
  }

  .lisact {
    color:#fff;
  }

  .listot{
    color:#271080;
  }

  .navlink {
    text-decoration: none; 
    width:100%; 
    color:#271080;
    padding: 0px;
  }

  .navlinkA {
    text-decoration: none; 
    width:100%; 
    background-color: #271080;
    color:#fff;
    height:100%;
    padding: 0px;
  }

  .icon-drawer{
    color:unset;
  }

  .list-item-button{
    display:flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height:55px;
    transition: all .2s ease-in-out;
  }

  .list-item-text{
    font-size: 10px;
    font-weight: bolder;
  }